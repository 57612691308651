import React, {createContext, useEffect, useState} from "react";
import Fetcher from "../services/Fetcher";
import ImageData from "../model/ImageData";
import Sprite from "../model/Sprite";
import ImageLoader from "../services/ImageLoader";

interface GameContextType {
    characterSpriteConfig: Sprite,
    imageGetter: Function
}

export const GameContext = createContext<GameContextType | undefined>(undefined);

const Game = (props: any) => {

    const [characterSpriteConfig, setCharacterSpriteConfig] = useState<Sprite | undefined>();
    const [imagesByName, setImagesByName] = useState<Map<string, HTMLImageElement> | undefined>();

    useEffect(() => {
        new Fetcher("/data/sprites/character.json")
            .onSuccess((json: any) => {
                setCharacterSpriteConfig(Sprite.fromJSON(json));
            })
            .fetch();
        new Fetcher("/data/images.json")
            .onSuccess((jsonArray: any[]) => {
                const images = jsonArray.map(json => ImageData.fromJSON(json));
                new ImageLoader().loadImages(images).then(imagesByName => setImagesByName(imagesByName));
            })
            .fetch();
    }, []);

    if (!characterSpriteConfig || !imagesByName) {
        return;
    }

    const gameContext: GameContextType = {
        characterSpriteConfig: characterSpriteConfig,
        imageGetter: (imageId: string): HTMLImageElement => {
            const image = imagesByName.get(imageId)
            if (!image) {
                throw new Error("Unable to get image " + imageId);
            }
            return image
        }
    };

    return (
        <GameContext.Provider value={gameContext}>
            {props.children}
        </GameContext.Provider>
    );

}

export default Game;