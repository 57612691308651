class ImageData {

    private readonly _id: string;
    private readonly _src: string;
    private readonly _name: string;
    private readonly _type: string;

    private constructor(
        id: string,
        src: string,
        name: string,
        type: string,
    ) {
        this._id = id;
        this._src = src;
        this._name = name;
        this._type = type;
    }

    public static fromJSON(json: {
        id: string,
        src: string,
        name: string,
        type: string
    }): ImageData {
        return new ImageData(
            json.id,
            json.src,
            json.name,
            json.type
        );
    }

    get id(): string {
        return this._id;
    }

    get src(): string {
        return this._src;
    }

    get name(): string {
        return this._name;
    }

    get type(): string {
        return this._type;
    }
}

export default ImageData;