import React, {createContext, CSSProperties, useEffect, useRef, useState} from "react";
import Constants from "../model/Constants";
import "../styles/background.scss"

interface BackgroundContextType {
    backgroundWidth: number,
    backgroundHeight: number,
    cellSize: number,
}

export const BackgroundContext = createContext<BackgroundContextType | undefined>(undefined);

const Background = (props: any) => {

    const backgroundWrapper = useRef<HTMLDivElement | null>(null);
    const background = useRef<HTMLDivElement | null>(null);

    const [backgroundWidth, setBackgroundWidth] = useState<number | undefined>();
    const [backgroundHeight, setBackgroundHeight] = useState<number | undefined>();
    const [cellSize, setCellSize] = useState<number | undefined>();

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleResize = () => {
        const parentWidth = backgroundWrapper.current!.offsetWidth;
        const parentHeight = backgroundWrapper.current!.offsetHeight;
        let backgroundWidth = parentWidth;
        let backgroundHeight = (parentWidth * 9) / 16;
        if (backgroundHeight > parentHeight) {
            backgroundHeight = parentHeight;
            backgroundWidth = (parentHeight * 16) / 9;
        }
        backgroundWidth = Math.floor(backgroundWidth / Constants.NUM_COLS) * Constants.NUM_COLS;
        backgroundHeight = Math.floor(backgroundHeight / Constants.NUM_ROWS) * Constants.NUM_ROWS;
        setCellSize(backgroundWidth / Constants.NUM_COLS);
        setBackgroundWidth(backgroundWidth);
        setBackgroundHeight(backgroundHeight);
    };

    const style: CSSProperties = {
        width: backgroundWidth,
        height: backgroundHeight
    };

    const backgroundContext: BackgroundContextType = {
        backgroundWidth: backgroundWidth!,
        backgroundHeight: backgroundHeight!,
        cellSize: cellSize!
    };

    return (
        <div className={"BackgroundWrapper"} ref={backgroundWrapper}>
            <div className={"Background"} ref={background} style={style}>
                <BackgroundContext.Provider value={backgroundContext}>
                    {props.children}
                </BackgroundContext.Provider>
            </div>
        </div>
    )

}

export default Background;