class Position {

    private readonly _col: number;
    private readonly _row: number;

    constructor(col: number, row: number) {
        this._col = col;
        this._row = row;
    }

    public static fromJSON(json: {
        col: number,
        row: number
    }): Position {
        return new Position(
            json.col,
            json.row
        );
    }

    get col(): number {
        return this._col;
    }

    get row(): number {
        return this._row;
    }

    equals(position: Position) {
        return this._col === position._col && this._row === position._row;
    }


}

export default Position;