import React, {useContext, useEffect, useRef, useState} from "react";
import {BackgroundContext} from "./Background";
import "../styles/canvas.scss"
import Item from "../model/Item";
import Position from "../model/Position";

interface CanvasProps {
    items: Item[];
    backgroundImage?: string;
    onClick?: Function;
}

const Canvas = (props: CanvasProps) => {

    const {backgroundWidth, backgroundHeight, cellSize} = useContext(BackgroundContext)!;
    const canvas = useRef<HTMLCanvasElement | null>(null);
    const [canvasContext, setCanvasContext] = useState<CanvasRenderingContext2D | null>(null);
    const [animationId, setAnimationId] = useState<number>(-1);

    useEffect(() => {
        setCanvasContext(canvas.current!.getContext('2d'));
    }, []);

    useEffect(() => {
        if (!canvasContext) {
            return;
        }
        if (animationId !== -1) {
            cancelAnimationFrame(animationId);
        }
        props.items.forEach(item => item.resize(cellSize));
        animate();
    }, [canvasContext, cellSize]);

    const animate = () => {
        props.items.forEach(item => {
            canvasContext!.clearRect(
                item.canvasPosX,
                item.canvasPosY,
                item.canvasWidth,
                item.canvasHeight
            );
            item.updateCanvasPosBuffer();
            item.images.forEach(image => {
                canvasContext!.drawImage(
                    image,
                    //sprite from whole PNG
                    item.spritePosX,
                    item.spritePosY,
                    item.spriteWidth,
                    item.spriteHeight,
                    //canvas position
                    item.canvasPosX,
                    item.canvasPosY,
                    item.canvasWidth,
                    item.canvasHeight
                );
            });
            item.updateSprite();
        })
        const animationId = requestAnimationFrame(animate);
        setAnimationId(animationId);
    };

    const style = () => {
        if (!props.backgroundImage) {
            return;
        }
        return {backgroundImage: `url(${props.backgroundImage})`};
    }

    const onClick = (event: React.MouseEvent<HTMLCanvasElement>) => {
        if (!props.onClick) {
            return;
        }
        const rect = canvas.current!.getBoundingClientRect();
        const col = Math.floor((event.clientX - rect.left) / cellSize);
        const row = Math.floor((event.clientY - rect.top) / cellSize);
        const clickedPosition = new Position(col, row);
        props.onClick(clickedPosition);
    }

    return (
        <canvas width={backgroundWidth}
                height={backgroundHeight}
                ref={canvas}
                className={"Canvas"}
                style={style()}
                onClick={onClick}/>
    )

}

export default Canvas;