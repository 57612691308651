import React from "react";
import {useNavigate} from "react-router-dom";

const Starter = () => {

    const navigate = useNavigate();

    return (
        <div className={"Starter"}>
            <button onClick={()=>navigate("/screen")}>Screen</button>
            <button onClick={()=>navigate("/sandbox")}>Sandbox</button>
            <button onClick={()=>navigate("/editor")}>Editor</button>
        </div>
    )

}

export default Starter;