import React from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Starter from "./pages/Starter";
import Sandbox from "./pages/Sandbox";
import Screen from "./pages/Screen";
import "./styles/main.scss"

const App = () => {

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Starter/>}/>
                <Route path="/screen" element={<Screen/>}/>
                <Route path="/sandbox" element={<Sandbox/>}/>
            </Routes>
        </BrowserRouter>
    )

};

export default App;