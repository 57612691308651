import React, {useContext, useEffect, useState} from "react";
import Area from "../model/Area";
import Item from "../model/Item";
import Fetcher from "../services/Fetcher";
import {GameContext} from "./Game";
import Canvas from "./Canvas";
import Position from "../model/Position";
import {shortestPath} from "../services/Pathfinder";
import Direction from "../model/Direction";

const Level = () => {

    const {imageGetter, characterSpriteConfig} = useContext(GameContext)!;

    const [backgroundImage, setBackgroundImage] = useState<string | undefined>();
    const [hero, setHero] = useState<Item | undefined>();
    const [areas, setAreas] = useState<Area[] | undefined>();
    const [characters, setCharacters] = useState<Item[] | undefined>();
    const [locked, setLocked] = useState<boolean>(false);

    useEffect(() => {
        new Fetcher("/data/level.json")
            .onSuccess((json: any) => {
                setBackgroundImage(imageGetter(json["imageId"]).src);
                setHero(Item.fromJSON(json["hero"], characterSpriteConfig, imageGetter));
                setAreas((json["areas"] as any[]).map(levelArea => Area.fromJSON(levelArea)));
                setCharacters((json["characters"] as any[]).map(character => Item.fromJSON(character, characterSpriteConfig, imageGetter)));
            })
            .fetch();
    }, []);

    if (!backgroundImage || !hero || !areas || !characters) {
        return;
    }

    const onClick = (clickedPosition: Position) => {
        if (locked) {
            return;
        }
        //if we clicked on hero return
        if (hero.position.equals(clickedPosition)) {
            return;
        }
        //check if we clicked on character
        const clickedCharacter = characters.find(character => character.position.equals(clickedPosition));
        //if we didn't click on character but clicked on area then return
        if (!clickedCharacter && areas.some(area => area.isInside(clickedPosition))) {
            return;
        }
        //if we did click on character, but we are in its engaging position then just face it
        if (clickedCharacter && clickedCharacter.engagingPosition.equals(hero.position)) {
            const direction = clickedCharacter.engagingDirection;
            hero.idle(direction);
            return;
        }
        //get target position and walk
        setLocked(true);
        const targetPosition = clickedCharacter ? clickedCharacter.engagingPosition : clickedPosition;
        const path = shortestPath(hero.position, targetPosition, areas);
        hero.walk(path, () => {
            setLocked(false);
            const targetDirection = clickedCharacter ? clickedCharacter.engagingDirection : Direction.BOTTOM;
            hero.idle(targetDirection);
        });
    }

    return (
        <React.Fragment>
            <Canvas backgroundImage={backgroundImage} items={characters}/>
            <Canvas onClick={onClick} items={[hero]}/>
        </React.Fragment>
    );

}

export default Level;