import Position from "./Position";
import Sprite from "./Sprite";
import ItemStatus from "./ItemStatus";
import Direction from "./Direction";

class Item {

    private readonly _name: string;
    private _position: Position;
    private _direction: Direction;
    private _status: ItemStatus;
    private readonly _sprite: Sprite;
    private readonly _images: HTMLImageElement[];
    //canvas stuff
    private _cellSize: number = -1;
    private _canvasPosX: number = -1;
    private _canvasPosY: number = -1;
    private _spriteSequenceIndex: number = 0;
    private _spriteCheckTime: number = 0;
    private _onWalkFinish: Function = () => {
    };

    private constructor(name: string, position: Position, direction: Direction, sprite: Sprite, images: HTMLImageElement[]) {
        this._name = name;
        this._position = position;
        this._direction = direction;
        this._status = ItemStatus.IDLE;
        this._sprite = sprite;
        this._images = images;
    }

    public static fromJSON(json: {
        name: string
        position: {
            col: number,
            row: number
        },
        direction: string,
        imageIds: string[]
    }, sprite: Sprite, imageGetter: Function,): Item {
        return new Item(
            json.name,
            Position.fromJSON(json.position),
            Direction.fromName(json.direction),
            sprite,
            json.imageIds.map(imageId => imageGetter(imageId))
        );
    }

    get name(): string {
        return this._name;
    }

    get position(): Position {
        return this._position;
    }

    get engagingPosition(): Position {
        let engagingCol = this.position.col + this.direction.colDelta;
        let engagingRow = this.position.row + this.direction.rowDelta;
        return new Position(engagingCol, engagingRow);
    }

    get engagingDirection(): Direction {
        return Direction.fromPositions(this.engagingPosition, this.position);
    }

    get direction(): Direction {
        return this._direction;
    }

    get sprite(): Sprite {
        return this._sprite;
    }

    get status(): ItemStatus {
        return this._status;
    }

    get images(): HTMLImageElement[] {
        return this._images;
    }

    get movingSpeed(): number {
        return this._cellSize / 5;
    }

    get canvasWidth(): number {
        return this._cellSize * this.sprite.scale
    }

    get canvasHeight(): number {
        return this._cellSize * this.sprite.scale
    }

    get canvasPosX(): number {
        return this._canvasPosX;
    }

    get canvasPosY(): number {
        return this._canvasPosY;
    }

    get spriteWidth(): number {
        return this._sprite.width;
    }

    get spriteHeight(): number {
        return this._sprite.height;
    }

    get spritePosX(): number {
        const spriteCol = this.sequence[this._spriteSequenceIndex].col;
        return spriteCol * this.spriteWidth;
    }

    get spritePosY(): number {
        const spriteRow = this.sequence[this._spriteSequenceIndex].row;
        return spriteRow * this.spriteHeight;
    }

    private expectedCanvasPosX(): number {
        return (this.position.col * this._cellSize) - ((this.canvasWidth - this._cellSize) / 2);
    }

    private expectedCanvasPosY(): number {
        return (this.position.row * this._cellSize) - (this.canvasHeight - this._cellSize);
    }

    resize(cellSize: number) {
        this._cellSize = cellSize;
        this._canvasPosX = this.expectedCanvasPosX();
        this._canvasPosY = this.expectedCanvasPosY();
    }

    idle(direction: Direction) {
        this._direction = direction;
        this._status = ItemStatus.IDLE;
        this.resetSpriteSequence();
    }

    walk(path: Position[], onFinish: Function) {
        this._status = ItemStatus.WALKING;
        const walkToNextPosition = (index: number) => {
            if (index >= path.length) {
                onFinish();
                return;
            }
            const origin = path[index - 1];
            const target = path[index];
            this._direction = Direction.fromPositions(origin, target);
            this._position = target;
            this.resetSpriteSequence();
            this._onWalkFinish = () => walkToNextPosition(index + 1);
        };
        walkToNextPosition(1);
    }

    updateCanvasPosBuffer() {
        const expectedCanvasPosX = this.expectedCanvasPosX();
        const expectedCanvasPosY = this.expectedCanvasPosY();
        const canvasPosXOffset = expectedCanvasPosX - this.canvasPosX;
        const canvasPosYOffset = expectedCanvasPosY - this.canvasPosY;
        if (canvasPosXOffset !== 0) {
            if (Math.abs(canvasPosXOffset) < this.movingSpeed) {
                this._canvasPosX = expectedCanvasPosX;
            } else {
                this._canvasPosX = this._canvasPosX + (Math.sign(canvasPosXOffset) * this.movingSpeed);

            }
        }
        if (canvasPosYOffset !== 0) {
            if (Math.abs(canvasPosYOffset) < this.movingSpeed) {
                this._canvasPosY = expectedCanvasPosY;
            } else {
                this._canvasPosY = this._canvasPosY + (Math.sign(canvasPosYOffset) * this.movingSpeed);
            }
        }

        if (expectedCanvasPosX === this.canvasPosX && expectedCanvasPosY === this.canvasPosY &&
            (canvasPosXOffset !== 0 || canvasPosYOffset !== 0)) {
            this._onWalkFinish!();
        }
    }

    updateSprite() {
        const now = performance.now();
        if (now - this._spriteCheckTime! <= this._sprite.frameTime) {
            return;
        }
        if (this._spriteSequenceIndex === this.sequence.length - 1) {
            this._spriteSequenceIndex = 0;
        } else {
            this._spriteSequenceIndex++;
        }
        this._spriteCheckTime = now
    }

    private get sequence() {
        return this._sprite.getSequence(this.status, this.direction)
    }

    private resetSpriteSequence() {
        this._spriteCheckTime = 0;
        this._spriteSequenceIndex = 0;
    }

}

export default Item;