import ItemStatus from "./ItemStatus";
import Direction from "./Direction";
import Position from "./Position";

class Sprite {

    private readonly _width: number;
    private readonly _height: number;
    private readonly _scale: number;
    private readonly _frameTime: number;
    private readonly _sequences: Map<ItemStatus, Map<Direction, Position[]>>

    private constructor(
        width: number,
        spriteHeight: number,
        scale: number,
        frameTime: number,
        sequences: Map<ItemStatus, Map<Direction, Position[]>>
    ) {
        this._width = width;
        this._height = spriteHeight;
        this._scale = scale;
        this._frameTime = frameTime;
        this._sequences = sequences;
    }

    public static fromJSON(json: {
        width: number,
        height: number,
        scale: number;
        frameTime: number;
        sequences: {
            [status: string]: {
                [direction: string]: { start: { col: number, row: number }, end: { col: number, row: number } }
            }
        }
    }): Sprite {
        const sequences = new Map<ItemStatus, Map<Direction, Position[]>>();
        for (const status in json.sequences) {
            const directionMap = new Map<Direction, Position[]>();
            for (const direction in json.sequences[status]) {
                const sequenceJson = json.sequences[status][direction];
                const start = Position.fromJSON(sequenceJson.start);
                const end = Position.fromJSON(sequenceJson.end);
                directionMap.set(Direction.fromName(direction), this.getPositionsInBetween(start, end));
            }
            sequences.set(ItemStatus.fromName(status), directionMap);
        }
        return new Sprite(
            json.width,
            json.height,
            json.scale,
            json.frameTime,
            sequences
        );
    }

    getSequence(printableStatus: ItemStatus, direction: Direction): Position[] {
        const sequence = this._sequences.get(printableStatus)?.get(direction);
        if (!sequence) {
            throw new Error("Positions not found for status " + printableStatus + " and direction " + direction)
        }
        return sequence;
    }

    get width(): number {
        return this._width;
    }

    get height(): number {
        return this._height;
    }

    get scale(): number {
        return this._scale;
    }

    get frameTime(): number {
        return this._frameTime;
    }

    private static getPositionsInBetween(start: Position, end: Position): Position[] {
        const positions = [];
        if (start.col === end.col) {
            const startY = Math.min(start.row, end.row);
            const endY = Math.max(start.row, end.row);
            for (let y = startY; y <= endY; y++) {
                positions.push(new Position(start.col, y));
            }
        } else if (start.row === end.row) {
            const startX = Math.min(start.col, end.col);
            const endX = Math.max(start.col, end.col);
            for (let x = startX; x <= endX; x++) {
                positions.push(new Position(x, start.row));
            }
        }
        return positions;
    }
}

export default Sprite;