class Fetcher {

    private readonly _basePath: string;
    private readonly _path: string;
    private _options: any;
    private _successHandler: Function;
    private _errorHandler: Function;
    private _notFoundHandler: Function;

    constructor(path: string) {
        this._basePath = window.location.origin
        this._path = path;
        this._options = {};
        this._successHandler = (data: any | any[]) => console.error(data);
        this._errorHandler = (error: Error) => console.error(error.message);
        this._notFoundHandler = () => console.error('Resource not found');
    }

    withPostBody(postBody: any) {
        this._options = {
            method: "POST",
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(postBody)
        }
        return this;
    }

    withPostData(postData: any) {
        this._options = {
            method: "POST",
            body: postData
        }
        return this;
    }

    onSuccess(callback: Function) {
        this._successHandler = callback;
        return this;
    }

    onError(callback: Function) {
        this._errorHandler = callback;
        return this;
    }

    onNotFound(callback: Function) {
        this._notFoundHandler = callback;
        return this;
    }

    fetch() {
        fetch(this._basePath + this._path, this._options)
            .then((response) => {
                if (response.status === 404) {
                    this._notFoundHandler();
                    return;
                }
                if (!response.ok) {
                    throw new Error("Unexpected response code " + response.status);
                }
                return response.json();
            })
            .then((json) => this._successHandler(json))
            .catch((error) => this._errorHandler(error))
    }


}

export default Fetcher;