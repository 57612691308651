import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import Game from "../components/Game";
import Background from "../components/Background";
import Level from "../components/Level";

const Screen = () => {

    const navigate = useNavigate();

    const fullScreenChangeEventKeys = [
        "fullscreenchange",
        "webkitfullscreenchange",
        "mozfullscreenchange",
        "msfullscreenchange"
    ]

    const fullScreenRequestKeys = [
        "requestFullscreen",
        "webkitRequestFullscreen",
        "mozRequestFullScreen",
        "msRequestFullscreen"
    ]

    const exitFullScreenRequestKeys = [
        "exitFullscreen",
        "webkitExitFullscreen",
        "mozCancelFullScreen",
        "msExitFullscreen"
    ]

    useEffect(() => {
        openFullScreen();
    }, []);

    useEffect(() => {
        fullScreenChangeEventKeys.forEach(fullScreenChangeEvent =>
            document.addEventListener(fullScreenChangeEvent, handleFullScreenChangeEvent)
        );
        return () => {
            fullScreenChangeEventKeys.forEach(fullScreenChangeEvent =>
                document.removeEventListener(fullScreenChangeEvent, handleFullScreenChangeEvent)
            );
        }
    }, []);

    const handleFullScreenChangeEvent = () => {
        if (!document.fullscreenElement) {
            finishCloseFullScreen();
        }
    };

    const finishCloseFullScreen = () => {
        screen.orientation.unlock();
        navigate("/");
    }

    const openFullScreen = () => {
        const element = document.documentElement as any;
        for (const fullScreenRequestKey of fullScreenRequestKeys) {
            if (fullScreenRequestKey in element) {
                element[fullScreenRequestKey]()
                    .then(() => {
                        if (window.innerWidth <= 768) {
                            const screenOrientation = screen.orientation as any;
                            screenOrientation["lock"]("landscape").catch(() => {
                            });
                        }
                    })
                    .catch((err: Error) => {
                        //TODO what to do show
                        console.error("Unable to enter full screen", err);
                    });
                break;
            }
        }
    }

    //TODO do not delete, can be used by button
    const closeFullScreen = () => {
        const anyDocument = document as any;
        for (const exitFullScreenRequestKey of exitFullScreenRequestKeys) {
            if (exitFullScreenRequestKey in anyDocument) {
                anyDocument[exitFullScreenRequestKey]()
                    .then(() => finishCloseFullScreen())
                    .catch((err: Error) => {
                        //TODO what to do show
                        console.error("Unable to close full screen", err);
                    });
                break;
            }
        }
    }

    return (
        <React.Fragment>
            <div className={"CloseButton"}>
                <button onClick={closeFullScreen}>Close</button>
            </div>
            <Game>
                <Background>
                    <Level/>
                </Background>
            </Game>
        </React.Fragment>
    )
}

export default Screen;