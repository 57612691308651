import Position from "./Position";

class Area {

    private readonly _position: Position
    private readonly _width: number;
    private readonly _height: number;

    constructor(
        position: Position,
        width: number,
        height: number,
    ) {
        this._position = position;
        this._width = width;
        this._height = height;
    }

    public static fromJSON(json: {
        position: {
            col: number,
            row: number
        },
        width: number,
        height: number
    }): Area {
        return new Area(
            Position.fromJSON(json.position),
            json.width,
            json.height
        );
    }

    get position(): Position {
        return this._position;
    }

    get width(): number {
        return this._width;
    }

    get height(): number {
        return this._height;
    }

    isInside(position : Position): boolean {
        const areaCol = this._position.col;
        const areaRow = this._position.row;
        const insideCol = position.col >= areaCol && position.col <= (areaCol + this.width - 1);
        const insideRow = position.row >= areaRow && position.row <= (areaRow + this.height - 1);
        return insideCol && insideRow;
    }

}

export default Area;