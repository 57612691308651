import ImageData from "../model/ImageData";

class ImageLoader {

    loadImages(imageDataList: ImageData[]): Promise<Map<string, HTMLImageElement>> {
        const imagesByName = new Map<string, HTMLImageElement>();
        const loadPromises = imageDataList.map(imageData => this.loadImage(imageData));
        return Promise
            .all(loadPromises)
            .then(loadedImages => {
                loadedImages.forEach(loadedImage => imagesByName.set(loadedImage.id, loadedImage.image));
                return imagesByName; // Return the imagesByName map here
            });
    }

    private loadImage(imageData: ImageData): Promise<{ id: string, image: HTMLImageElement }> {
        return new Promise((resolve, reject) => {
            const newImage = new Image();
            newImage.src = imageData.src;
            newImage.onload = () => resolve({id: imageData.id, image: newImage});
            newImage.onerror = reject;
        });
    }

}

export default ImageLoader;