class ItemStatus {

    private readonly _name: string;

    private constructor(name : string) {
        this._name = name;
    }

    public static IDLE =  new ItemStatus("idle");
    public static WALKING =  new ItemStatus("walking");

    public static VALUES = [ItemStatus.IDLE, ItemStatus.WALKING];


    get name(): string {
        return this._name;
    }

    static fromName(name: string): ItemStatus {
        const itemStatus = this.VALUES.find(status => status.name === name);
        if (!itemStatus) {
            throw new Error("Item status not found for " + name);
        }
        return itemStatus;
    }

}

export default ItemStatus;