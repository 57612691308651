import React from "react";
import Background from "../components/Background";
import Game from "../components/Game";
import Level from "../components/Level";

const Sandbox = () => {

    return (
        <Game>
            <Background>
                <Level/>
            </Background>
        </Game>
    )

}

export default Sandbox;